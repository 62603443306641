"use client"

import WebsiteCookieConsent from "@/components/cookie-consent/page"
import DarkModeButton from "@/components/dark-mode-button/page"
import ThemeProvider, {
  ThemeContext,
} from "@/components/dark-mode-button/theme-provider"
import FooterLayout from "@/components/footer/layout"
import NavBar from "@/components/navbar/page"
import TopJumpButton from "@/components/top-jump-button/layout"
import { PrimeReactProvider } from "primereact/api"
import { useContext, useEffect } from "react"

export default function ClientLayout(props: any) {
  return (
    //insert providers here
    <ThemeProvider>
      <Content> {props.children}</Content>
    </ThemeProvider>
  )
}

export function Content(props: any) {
  //use context of providers here

  // #region theme
  const { darkTheme, toggleTheme } = useContext(ThemeContext)
  useEffect(() => {
    document.documentElement.setAttribute(
      "data-theme",
      darkTheme ? "dark" : "light",
    )
  }, [darkTheme])
  // #endregion theme

  return (
    <>
      <DarkModeButton className="z-20" />
      <TopJumpButton className="z-20" />
      <div className="flex flex-col h-full">
        <PrimeReactProvider>
          <NavBar className={"shadow z-30"} />
          <main className="main grow flex my-8">{props.children}</main>
          <FooterLayout />
        </PrimeReactProvider>
      </div>
      <WebsiteCookieConsent />
    </>
  )
}
