"use client"

import { Variants, motion, useMotionValueEvent, useScroll } from "framer-motion"
import { useState } from "react"

export default function TopJumpButton(props: any) {
  const { scrollY } = useScroll()
  const [showButton, setShowButton] = useState(false)

  function scrollToTop() {
    window.scroll({ top: 0, behavior: "smooth" })
  }

  useMotionValueEvent(scrollY, "change", (latest) => {
    if (latest > 20) {
      setShowButton(true)
    } else if (latest < 20) {
      setShowButton(false)
    }
  })

  const item: Variants = {
    hidden: {
      y: 100,
      opacity: 0,
      transition: { duration: 0.5 },
    },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.8 },
    },
  }

  return (
    <div className={`fixed bottom-0 right-0 m-8 z-20`}>
      <motion.div
        variants={item}
        initial="hidden"
        animate={showButton ? "visible" : ""}
      >
        <button
          className="rounded-full bg-cookieConsentButtonBg color-textPrimary w-12 h-12 shadow-lg"
          type="button"
          onClick={scrollToTop}
        >
          <i className="pi pi-arrow-up font-black m-auto self-center align-middle" />
        </button>
      </motion.div>
    </div>
  )
}
