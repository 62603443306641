"use client"

import { useContext } from "react"
import styled from "styled-components"
import { ThemeContext } from "./theme-provider"

const DarkModeButtonDiv = styled.div`
  background-color: var(--primary-color);
`

/** A component to toggle the darkmode of the website
 * @returns
 */
export default function DarkModeButton(props: { className?: string }) {
  // const [darkMode, setDarkMode] = useState<boolean>(false)

  // function switchDarkMode() {
  //   setDarkMode(!darkMode)
  // }

  // useEffect(
  //   /** Apply changes to css*/
  //   () => {
  //     const value = darkMode ? "dark" : "light"
  //     const html = document.querySelector("html")
  //     html?.setAttribute("data-theme", value)
  //   },
  //   [darkMode],
  // )

  // useEffect(
  //   /** add onColorScheme changed by user listener */
  //   () => {
  //     const darkSchemePreference = window.matchMedia(
  //       "(prefers-color-scheme: dark)",
  //     )
  //     setDarkMode(darkSchemePreference.matches)

  //     /** match darkmode on change by user */
  //     const listener = (e: MediaQueryListEvent) => {
  //       setDarkMode(e.matches)
  //     }
  //     darkSchemePreference.addEventListener("change", listener)

  //     return () => {
  //       darkSchemePreference.removeEventListener("change", listener)
  //     }
  //   },
  //   [],
  // )

  const { darkTheme, toggleTheme } = useContext(ThemeContext)

  function switchDarkMode() {
    toggleTheme()
  }

  return (
    <DarkModeButtonDiv
      className={
        props.className +
        " fixed right-2 top-4 z-10 text-center align-middle rounded-full shadow-sm cursor-pointer "
      }
    >
      <button
        className="p-3 aspect-square text-center align-middle"
        onClick={switchDarkMode}
      >
        <i
          className={
            "pi align-top text-xl " + (darkTheme ? "pi-moon " : "pi-sun ")
          }
        ></i>
      </button>
    </DarkModeButtonDiv>
  )
}
