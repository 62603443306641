"use client"

import { Route, about, games, home, services, webViewer } from "@/data/routes"
import classNames from "classnames"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import styled from "styled-components"

const Background = styled.div`
  background-color: var(--primary-color);
  box-shadow: 0 1px 8px rgb(0 0 0 / 30%);
`

interface LinkData {
  content: string
  pathname: string
  iconUrl?: string
}

export default function NavBar(props: any) {
  const navRoutes: Array<Route> = [home, about, services, games, webViewer]
  const pathName = usePathname()

  return (
    <Background className={"NavBar pt-2 " + props.className}>
      <div className="flex flex-col">
        <div className="w-full mb-2 align-middle flex flex-col sm:justify-center sm:gap-2  sm:flex-row ">
          <div className="align-middle text-center">
            <Image
              className="inline-block py-2 w-auto h-12"
              src="/images/icons/logo.png"
              alt="Icon"
              width={32}
              height={32}
            />
            <h2 className="inline text-center align-middle">de Gavarelli</h2>
          </div>
          <div className="hidden mx-3 sm:visible text-center"></div>
          <h3 className="inline self-center">3D - Die virtuelle Realität</h3>
        </div>
        <div className="self-center overflow-hidden">
          <ul className="flex flex-row flex-wrap">
            {navRoutes.map((route, index) => {
              return (
                <li
                  className="hover:bg-stone-900 flex-1 text-center"
                  key={route.pathname}
                >
                  {/* <div className=" transform transition duration-200 hover:scale-110"> */}
                  <Link
                    className="flex justify-center px-3 font-bold text-link min-w-40 min-h-10
                    hover:text-linkHover transform transition duration-200 hover:scale-110 "
                    href={{ pathname: route.path }}
                  >
                    <span
                      className={
                        " inline self-center " +
                        classNames({
                          " text-white ": pathName === route.path,
                        })
                      }
                    >
                      {route.pathname}
                    </span>
                  </Link>
                  {/* </div> */}
                </li>
              )
            })}
          </ul>
        </div>
      </div>
    </Background>
  )
}
