import { about, home, impressum, Route } from "@/data/routes"
import classNames from "classnames"
import Link from "next/link"
import { usePathname } from "next/navigation"

export default function FooterLayout() {
  const links: Array<Route> = [home, impressum, about]
  const pathName = usePathname()

  return (
    <footer className="block w-full bg-primaryColor">
      <ul className="flex flex-col flex-wrap m-6 mb-3 justify-center justify-items-start xs:flex-row">
        {links.map((linkData, index) => {
          return (
            <li
              className="mx-6 my-1 flex-1 xs:flex-none text-center"
              key={linkData.pathname}
            >
              <Link
                href={{
                  pathname: linkData.path,
                }}
                className={
                  "font-bold text-link " +
                  " hover:text-white active:text-white " +
                  classNames({
                    " text-white ": pathName === linkData.path,
                  })
                }
              >
                {linkData.pathname}
              </Link>
            </li>
          )
        })}
      </ul>
    </footer>
  )
}
