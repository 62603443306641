"use client"
import CookieConsent from "react-cookie-consent"
import { motion } from "framer-motion"

export default function WebsiteCookieConsent() {
  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 1, ease: "easeIn" }}
      className="z-50"
    >
      <CookieConsent
        location="bottom"
        buttonText="Akzeptieren"
        cookieName="cookieConsent"
        style={{
          background: " var(--cockie-consent-bg-color) ",
        }}
        buttonStyle={{
          background: " var(--cookie-consent-buttton-bg-color) ",
          color: " var(--cookie-consent-buttton-text-color) ",
          fontSize: "13px",
        }}
        expires={90}
      >
        <div className="text-md">
          {/* This website uses cookies to enhance the user experience. Diese Website */}
          {"Diese Website verwendet Cookies zur Verbesserung der Nutzung. "}
          <br />
          <span className="text-sm">
            {"Weitere Informationen findest du "}
            <a href="#">{"hier"}</a>
            {"."}
          </span>
        </div>
      </CookieConsent>
    </motion.div>
  )
}
