
enum RouteEnum { Page, Generic, Component }

export type Route = {
    pathname: string
    path: string
    pathType: RouteEnum
}

export const about: Route = {
    pathname: 'Portfolio',
    path: '/portfolio',
    pathType: RouteEnum.Page
}

export const games: Route = {
    pathname: 'Videospiele',
    path: '/games',
    pathType: RouteEnum.Page
}

export const home: Route = {
    pathname: 'Startseite',
    path: '/',
    pathType: RouteEnum.Page
}

export const impressum: Route = {
    pathname: 'Impressum',
    path: '/impressum',
    pathType: RouteEnum.Page
}

export const services: Route = {
    pathname: 'Dienstleistungen',
    path: '/services',
    pathType: RouteEnum.Page
}

export const webViewer: Route = {
    pathname: '3D-WebViewer',
    path: '/webviewer',
    pathType: RouteEnum.Page
}

export const allRoutes: Array<Route> = [home, services, impressum, about, games]